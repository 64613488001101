import React from "react";
import PlusImage from "../plus/res/plusmodal/plusmodal5.jpg";
import { Typography } from "@mui/material";
import { preventWidows } from "../../utils/text";
import { Link } from "react-router-dom";
const PlusUpgrade = () => {
  return (
    <div>
      <Link to="/plus">
        <div className="w-full aspect-square md:rounded-2xl relative overflow-hidden min-h-[500px] flex items-end">
          <div className="absolute inset-0 bg-cover bg-center" style={{ backgroundImage: `url(${PlusImage})` }}></div>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-80"></div>
          <div className="flex flex-col items-start justify-end h-full px-4 py-8 md:px-8 md:py-12 relative z-10 ">
            <Typography variant="h4" className="mb-2 text-white font-medium">
              {preventWidows("Be the first to experience the future")}
            </Typography>
            <Typography variant="subtitle2" className="leading-snug text-secondaryText-100 font-normal">
              {preventWidows("Unlock early access to thisPantry+ for $12/month and keep the price ", 5)}
              <span className="whitespace-nowrap">
                forever
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 inline-block ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                  />
                </svg>
              </span>
            </Typography>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default PlusUpgrade;
