import { GET_DEVICE_INFO, DEVICE_INFO_ERROR } from "../actions/types";

const initialState = {
  platform: null,
  platformError: false,
  platformLoading: true,
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_DEVICE_INFO:
      return {
        ...state,
        platform: payload,
        platformError: false,
        platformLoading: false,
      };
    case DEVICE_INFO_ERROR:
      return {
        ...state,
        platform: "web",
        platformError: true,
        platformLoading: false,
      };
    default:
      return state;
  }
}
