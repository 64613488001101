import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Navigate } from "react-router-dom";

import { Capacitor } from "@capacitor/core";

//Components
import Helmet from "react-helmet";

import FeedManager from "../feed/FeedManager";
import Landing from "../landing/Landing";

const Home = ({ auth: { isAuthenticated, user, loading } }) => {
  //Get query parameters, if any (social login callback)
  //MUI Styling

  const theme = useTheme();

  const [capacitorPlatform, setCapacitorPlatform] = useState(null);

  const getCapacitorPlatform = () => {
    const platform = Capacitor.getPlatform();

    if (platform) {
      setCapacitorPlatform(platform);
    } else {
      setCapacitorPlatform("web");
    }
  };
  useEffect(() => {
    getCapacitorPlatform();
  }, []);

  const isAuth = (
    <div className="h-full w-full md:w-full md:ml-0 md:pt-4 bg-background md:bg-neutral-50 min-h-screen">
      <Helmet>
        <title>thisPantry</title>
      </Helmet>
      <FeedManager />
    </div>
  );

  if (isAuthenticated && user) {
    if (user?.signupStage && user.signupStage > 0) {
      return <Navigate to={`/signup/${user.signupStage}`} replace />;
    } else {
      return isAuth;
    }
  } else {
    if (!capacitorPlatform || capacitorPlatform === "web") {
      return <Landing />;
    } else {
      return <Navigate to="/signup" replace />;
    }
  }
};

Home.propTypes = {
  auth: PropTypes.object.isRequired,
};

//Bring in state (Map to props)
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Home);
