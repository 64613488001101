import React, { Fragment, useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import theme from "./theme";
import "./App.css";
import { hydrateRoot } from "react-dom/client";

//Utils
import { initializeDeepLinks } from "./utils/deeplinks";

//Components

//Routing
import PrivateRoute from "./components/routing/PrivateRoute";
import OAuthFetchUser from "./components/auth/oauth/OAuthFetchUser";
import SignupStages from "./components/auth/SignupStages";
import NavigationListener from "./components/navigation/NavigationListener";

//Redux
import { Provider } from "react-redux";
import store, { history } from "./store";
import { loadUser } from "./actions/auth";
import { fetchDeviceInfo } from "./actions/device";

//=====COMPONENTS===== (Convert to lazy load before launch)
import Test from "./components/test/Test";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Home from "./components/home/Home";
import PantryHome from "./components/pantry/PantryHome";
import AddProduct from "./components/pantry/AddProduct";
import ScanReceipt from "./components/pantry/ScanReceipt";

//Navigation Manager
import Navigation from "./components/navigation/Navigation";

//Profile
import Profile from "./components/profile/Profile";
import EditProfile from "./components/profile/EditProfile";

//Settings
import Settings from "./components/settings/Settings";
import SettingsLegal from "./components/settings/sections/Policies";
import NotificationSettings from "./components/settings/sections/Notifications";
import AccountSettings from "./components/settings/sections/Account";
import DietarySettings from "./components/settings/sections/Dietary";
import SubscriptionSettings from "./components/settings/sections/Subscription";
import DeleteAccount from "./components/settings/sections/Delete";
import BlockSettings from "./components/settings/sections/Blocked";

//Static Pages
import Cookies from "./components/static/Cookies";
import Privacy from "./components/static/Privacy";
import Terms from "./components/static/Terms";

//Contact
import ContactPage from "./components/contact/ContactPage";

//Create
import CreateHome from "./components/create/CreateHome";
import CreatePost from "./components/create/CreatePost";
import CreateRecipe from "./components/create/CreateRecipe";

//Cookbook
import Cookbook from "./components/cookbook/Cookbook";
import CookingWalkthrough from "./components/cookbook/components/walkthrough/Walkthrough";
import RecipePage from "./components/recipes/standalone/RecipePage";
import CookbookSectionPage from "./components/cookbook/components/main/CookbookSectionPage";
import RecentlyCookedStandalone from "./components/cookbook/components/subsections/RecentlyCooked";
import BookmarkedStandalone from "./components/cookbook/components/subsections/Bookmarked";
import LovedByFriendsStandalone from "./components/cookbook/components/subsections/LovedByFriends";
import MyRecipesStandalone from "./components/cookbook/components/subsections/MyRecipes";
import TrendingStandalone from "./components/cookbook/components/subsections/Trending";
import FavouritesStandalone from "./components/cookbook/components/subsections/Favourites";
import CookbookSearchResults from "./components/cookbook/components/main/SearchResults";

//Notifications
import NotificationsPage from "./components/notifications/NotificationsPage";

//Search
import Search from "./components/search/Search";

//Alerts
import Alerts from "./components/layout/Alerts";

//Layout
import ComingSoon from "./components/layout/ComingSoon";

//Plus
import PlusPage from "./components/plus/PlusPage";

//Trending for SEO
import TrendingRecipes from "./components/trending/TrendingRecipes";

//Stripe
import CheckoutPage from "./components/stripe/CheckoutPage";
import SubscriptionSuccessPage from "./components/stripe/SubscriptionSuccessPage";
import SubscriptionTerms from "./components/stripe/SubscriptionTerms";

//Icons
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faEyeSlash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { faGoogle, faApple, faFacebook, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import StandalonePost from "./components/standalone/StandalonePost";

//Insights
import InsightsPage from "./components/insights/InsightsPage";

import { StatusBar, Style } from "@capacitor/status-bar";
import { Capacitor } from "@capacitor/core";

//DEV -> UPLOAD CONTENT FILES
import ContentUpload from "./components/shoot/ContentUpload";

//Add icons to library
library.add(faEye, faEyeSlash, faXmark, faGoogle, faApple, faFacebook, faXTwitter, faEnvelope);

//Lazy load
//import AboutPage from './components/layout/AboutPage';
//const AboutPage = lazy(() => import('./components/layout/AboutPage'));

const App = () => {
  const setupBackgroundHandler = async () => {
    // Initialize background handler
    const backgroundHandler = await import("./utils/background-handler");
  };

  const setStatusBar = async () => {
    const platform = Capacitor.getPlatform();
    if (platform === "ios") {
      await StatusBar.setStyle({ style: Style.Light });
    }
  };
  useEffect(() => {
    store.dispatch(loadUser());
    store.dispatch(fetchDeviceInfo());
    // Initialize deep links and store the cleanup function
    const unsubscribe = initializeDeepLinks();
    setupBackgroundHandler();
    setStatusBar();
    // Return cleanup function
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);
  /*
  const lazyFallback = (
    <Fragment>
      <Navbar path={'/'} />
      <section className="lazyFallback"></section>
    </Fragment>
  );
  /*
Inside Suspense, outside section
<Route
              path=""
              render={(props) => <Navbar path={props.location.pathname} />}
            />
  */

  /*

                    
                    
            */
  const lazyFallback = (
    <Fragment>
      <section className="lazyFallback"></section>
    </Fragment>
  );
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Router>
            <Navigation />
            <NavigationListener />
            <Fragment>
              <Suspense fallback={lazyFallback}>
                <section>
                  <Alerts />
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route
                      path="/welcome/:stageId"
                      element={<PrivateRoute element={SignupStages} requireSignupComplete={false} />}
                    />
                    <Route path="/signup" element={<Register />} />
                    <Route path="/pantry" element={<PrivateRoute element={PantryHome} />} />
                    <Route path="/pantry/scan" element={<PrivateRoute element={ScanReceipt} />} />
                    <Route path="/cookies" element={<Cookies />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/pantry/individual" element={<PrivateRoute element={AddProduct} />} />
                    <Route path="/cookbook" element={<PrivateRoute element={Cookbook} />} />
                    <Route
                      path="/cookbook/trending"
                      element={<PrivateRoute element={TrendingStandalone} section={"trending"} />}
                    />
                    <Route
                      path="/cookbook/recent"
                      element={<PrivateRoute element={RecentlyCookedStandalone} section={"recent"} />}
                    />
                    <Route path="/cookbook/myrecipes" element={<PrivateRoute element={MyRecipesStandalone} />} />
                    <Route
                      path="/cookbook/loved"
                      element={<PrivateRoute element={LovedByFriendsStandalone} section={"loved"} />}
                    />
                    <Route
                      path="/cookbook/bookmarked"
                      element={<PrivateRoute element={BookmarkedStandalone} section={"bookmarked"} />}
                    />
                    <Route
                      path="/cookbook/favourites"
                      element={<PrivateRoute element={FavouritesStandalone} section={"favorites"} />}
                    />
                    <Route path="/cookbook/search" element={<PrivateRoute element={CookbookSearchResults} />} />

                    <Route path="/cookbook/:recipeID" element={<PrivateRoute element={CookingWalkthrough} />} />
                    <Route path="/editprofile" element={<PrivateRoute element={EditProfile} />} />
                    <Route
                      path="/settings"
                      element={<PrivateRoute element={Settings} requireSignupComplete={false} />}
                    />
                    <Route
                      path="/settings/notifications"
                      element={<PrivateRoute element={NotificationSettings} requireSignupComplete={false} />}
                    />
                    <Route
                      path="/settings/account"
                      element={<PrivateRoute element={AccountSettings} requireSignupComplete={false} />}
                    />
                    <Route
                      path="/settings/dietary"
                      element={<PrivateRoute element={DietarySettings} requireSignupComplete={false} />}
                    />
                    <Route
                      path="/settings/subscription"
                      element={<PrivateRoute element={SubscriptionSettings} requireSignupComplete={false} />}
                    />
                    <Route
                      path="/settings/delete"
                      element={<PrivateRoute element={DeleteAccount} requireSignupComplete={false} />}
                    />
                    <Route
                      path="/settings/blocked"
                      element={<PrivateRoute element={BlockSettings} requireSignupComplete={false} />}
                    />
                    <Route path="/notifications" element={<PrivateRoute element={NotificationsPage} />} />
                    <Route path="/search" element={<PrivateRoute element={Search} />} />
                    <Route path="/create" element={<PrivateRoute element={CreateHome} />} />
                    <Route path="/insights" element={<PrivateRoute element={InsightsPage} />} />
                    <Route path="/kitchen-dashboard" element={<PrivateRoute element={ComingSoon} />} />
                    <Route path="/plus" element={<PlusPage />} />
                    <Route path="/create/post" element={<PrivateRoute element={CreatePost} />} />
                    <Route path="/create/recipe" element={<PrivateRoute element={CreateRecipe} />} />
                    <Route path="/create/recipe/:recipeid" element={<PrivateRoute element={CreateRecipe} />} />
                    <Route path="/settings/policies" element={<SettingsLegal />} />

                    <Route path="/:username" element={<Profile />} />
                    <Route path="/:username/:recipeURL" element={<RecipePage />} />
                    <Route path="/:username/post/:postID" element={<StandalonePost />} />
                    <Route path="/oauth/:type/:token" element={<OAuthFetchUser />} />
                    <Route path="/plus/join" element={<PrivateRoute element={CheckoutPage} />} />
                    <Route path="/plus/welcome" element={<PrivateRoute element={SubscriptionSuccessPage} />} />
                    <Route path="/plus/terms" element={<SubscriptionTerms />} />
                    <Route path="/trending/recipes" element={<TrendingRecipes />} />
                  </Routes>
                </section>
              </Suspense>
            </Fragment>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  );
};
export default App;
