import React, { useEffect, useState } from "react";
import api from "../../api/api";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Typography, Divider, Skeleton } from "@mui/material";

import { ReactComponent as PlusLogo } from "../plus/res/plus/pluslogo-duolight.svg";
const CheckoutPriceSelector = ({ auth: { isAuthenticated, isPlus }, device: { platform } }) => {
  const [clientSecret, setClientSecret] = useState(null);
  const [clientSecretLoading, setClientSecretLoading] = useState(true);
  const [clientSecretError, setClientSecretError] = useState(null);
  //Get customer session to prefill
  const getCustomerClientSecret = async () => {
    try {
      const response = await api.post("/stripe/create-pricing-table");
      setClientSecret(response.data.clientSecret);
      setClientSecretLoading(false);
      setClientSecretError(null);
    } catch (error) {
      console.error(error);
      setClientSecretLoading(false);
      setClientSecretError(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getCustomerClientSecret();
    }
  }, [isAuthenticated]);

  const stripePriceSelector = (
    <div className="w-full">
      <stripe-pricing-table
        pricing-table-id="prctbl_1QRKc1EG8mQA0pyFFnubxZaz"
        publishable-key="pk_test_51PKuT2EG8mQA0pyFza3a2NTP1WHbvb8AYmvb6JI79UdmQJx0xZm1XLqkbkrgMvSiQwp1edtOvJfG2wCHGrL8xFm200OTsmaHHe"
        customer-session-client-secret={clientSecret}
      ></stripe-pricing-table>
    </div>
  );

  const skeletonPriceSelector = (
    <div className="w-full h-full">
      <div className="flex flex-col gap-4">
        <Skeleton variant="rectangular" width="100%" height={40} />
        <Skeleton variant="rectangular" width="100%" height={40} />
        <Skeleton variant="rectangular" width="100%" height={40} />
      </div>
    </div>
  );

  const iOSPriceSelector = (
    <div>
      <div>iOS Price Selector</div>
    </div>
  );

  const noAuthPriceSelector = (
    <div>
      <div>No Auth Price Selector</div>
    </div>
  );

  const priceSelectorError = (
    <div>
      <div>Error loading price selector</div>
    </div>
  );

  const alreadySubscribed = (
    <div>
      <div>Already Subscribed</div>
    </div>
  );

  const determinePriceSelector = () => {
    if (isAuthenticated) {
      if (isPlus) {
        return alreadySubscribed;
      } else {
        if (platform === "ios") {
          return iOSPriceSelector;
        } else {
          if (clientSecretLoading) {
            return skeletonPriceSelector;
          } else if (clientSecretError) {
            return priceSelectorError;
          } else {
            return stripePriceSelector;
          }
        }
      }
    } else {
      return noAuthPriceSelector;
    }
  };

  return (
    <div className="bg-mainGreen min-h-[786px]">
      <div className="bg-mainGreen">
        <div className="py-16 md:py-32 flex flex-col xl:flex-row gap-16 xl:gap-16 items-center max-w-4xl mx-auto">
          <div className="w-full max-w-[425px] px-8 flex flex-col">
            <Typography variant="h5" className="text-white">
              Try
            </Typography>
            <PlusLogo className="h-16 w-auto my-2 md:mx-8 fill-mainGreen-700" />
            <Typography variant="h5" className="text-white text-right">
              free for 7 days
            </Typography>
            <Typography variant="subtitle2" className="font-normal mt-4 text-white w-full">
              Then subscribe bi-weekly,<br></br>monthly, or annually. Cancel anytime.
            </Typography>
          </div>

          <Divider orientation="vertical" flexItem className="border-white hidden xl:flex" />
          <Divider flexItem className="border-white/40 flex xl:hidden" />
          <div className="w-full">{determinePriceSelector()}</div>
        </div>
      </div>
    </div>
  );
};

CheckoutPriceSelector.propTypes = {
  auth: PropTypes.object.isRequired,
  device: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  device: state.device,
});

export default connect(mapStateToProps)(CheckoutPriceSelector);
