import { Capacitor } from "@capacitor/core";
import { GET_DEVICE_INFO, DEVICE_INFO_ERROR } from "./types";

export const fetchDeviceInfo = () => async (dispatch) => {
  try {
    const deviceInfo = Capacitor.getPlatform();
    dispatch({
      type: GET_DEVICE_INFO,
      payload: deviceInfo,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: DEVICE_INFO_ERROR,
      payload: error,
    });
  }
};
